/** BCP-47 Browser locales */
export const SYSTEM_LOCALES = ('navigator' in (typeof globalThis === 'undefined' ? self : globalThis))
  ? ((): string[] => [
    ...new Set((navigator.languages || /* IE11 */ [ navigator.language ]).flatMap(e => [
      e.toLowerCase(),                // with dialect
      e.split('-')[0]?.toLowerCase(), // without dialect
    ])),
  ])()
  : []

if (import.meta.hot) {
  import.meta.hot.accept()
}
