/* eslint-disable no-console */

import { isDebug } from '../env'

declare global {
  interface Console {
    deprecate(...data: any[]): void;
  }
}

console.deprecate = (...data) => isDebug ? console.debug(...data) : null
